import { InputField } from "@russpass/partner-front-ui";
import { FieldArray, getIn, useFormikContext } from "formik";
import React, { MutableRefObject, useCallback, useMemo } from "react";
import { DatePickerField } from "../DatePickerField";
import classNames from "classnames";
import { ReactComponent as ArrowLeft } from "../../../../../../../assets/images/icons/ic_plus.svg";
import { ReactComponent as Trash } from "../../../../../../../assets/images/icons/ic_trash.svg";
import styles from "./styles.module.sass";
import { CalendarColors, initialColorsList } from "../../index";
import { maskedSeparatePrice } from "../../../../../../../utils/validation";

type Props = {
    name: string;
    colorsList: MutableRefObject<{ [key in CalendarColors]: string }>;
    disabled?: boolean;
};

const MAX_NUMBER_OF_RATES = 4;

const getDisabledWeekDays = (items: { price: string; scheduleType: string; dates: string[]; id: string }[]) => {
    const result: string[] = [];
    items?.forEach((item) => item?.dates?.forEach((date) => result.push(date)));
    return result;
};

export const RatesField = ({ name, colorsList, disabled }: Props) => {
    const { values, setFieldValue, setErrors } = useFormikContext();

    const items = useMemo(() => getIn(values, name), [name, values]);
    const disabledWeekDays = useMemo(() => getDisabledWeekDays(items), [items]);

    const isFirstItem = useMemo(() => items.length <= 1, [items.length]);

    const isShowAddButton = useMemo(() => {
        const lastIdx = items.length - 1;
        return !!(items[lastIdx]?.price && items[lastIdx]?.dates?.length && lastIdx < MAX_NUMBER_OF_RATES - 1);
    }, [items]);

    const onDelete = useCallback(
        (remove: (index: number) => void, idx: number, id: string) => {
            if (!isFirstItem) {
                remove(idx);

                let colors = colorsList.current;
                const colorsKeys = Object.keys(colorsList.current);

                colorsKeys.forEach((color) => {
                    const calendarColor = color as CalendarColors;
                    if (colorsList.current[calendarColor] === id) {
                        colors = { ...colors, [calendarColor]: 0 };
                    }
                });
                colorsList.current = colors;
                return;
            }
            setFieldValue(name, [{}]);
            colorsList.current = initialColorsList;
        },
        [colorsList, isFirstItem, name, setFieldValue]
    );

    return (
        <FieldArray
            name={name}
            render={({ push, remove }) => (
                <>
                    {items.map((item: any, idx: number) => {
                        const hasData = item.dates && item.price;
                        return (
                            <div className={styles.item} key={idx}>
                                <DatePickerField
                                    name={`${name}[${idx}]`}
                                    label="Тариф действует"
                                    placeholder="Выбрать"
                                    disabledWeekDays={disabledWeekDays}
                                    multipleRangeSelect
                                    disabled={disabled}
                                    classes={styles.dates}
                                />
                                <InputField
                                    noHover
                                    name={`${name}[${idx}].price`}
                                    label="Цена за ночь"
                                    placeholder="1 000 ₽"
                                    renderComponent={maskedSeparatePrice}
                                    disabled={disabled}
                                />
                                {(hasData || idx > 0) && items.length > 1 && !disabled && (
                                    <Trash
                                        className={styles.item__trash}
                                        onClick={() => onDelete(remove, idx, item.id)}
                                    />
                                )}
                            </div>
                        );
                    })}
                    {isShowAddButton && (
                        <button
                            className={classNames("button button--third")}
                            onClick={() => {
                                push({});
                                setErrors({
                                    tariff: "",
                                });
                            }}
                            disabled={disabled}
                        >
                            <ArrowLeft className="button__icon button__icon--left" /> <div>Добавить тариф</div>
                        </button>
                    )}
                </>
            )}
        />
    );
};
