import { useCallback, useEffect, useMemo, useState } from "react";
import { getOrdersShort, getOrdersAll } from "../../api/orders";
import { GET_DATA_ERROR } from "../../constants/errors";
import eventDispatcher from "../../lib/event-dispatcher";
import { StatusRequestEnum } from "@russpass/partner-front-ui";
import { OrdersWithPaginate } from "./types";
import { INITIAL_FILTERS, OrderFilters, OrdersSorter, orderStatuses, PAGINATE_SIZE } from "./constants";
import useDebounceFilters from "../../hooks/useDebounceFilters";
import useUserInfo from "../../hooks/useUserInfo";
import clone from "lodash/clone";
import { useOrderFiltersContext } from "./context/useOrderFiltersContext";
import { isAvailableOrdersShortList } from "../../constants/feature-toggles";

const initialData = {
    content: [],
    totalPages: 0,
    size: PAGINATE_SIZE,
    totalElements: 0,
};

type Props = {
    status: string;
};

const fetchOrders = isAvailableOrdersShortList ? getOrdersShort : getOrdersAll;

const useOrders = ({ status }: Props) => {
    const { filters, sort, setIsEmpty, changeFilter } = useOrderFiltersContext();
    const [isLoadingContent, setIsLoadingContent] = useState<boolean>(true);

    const [data, setData] = useState<OrdersWithPaginate>(clone(initialData));

    const [total, setTotal] = useState<number>(0);

    const [numberPage, setNumberPage] = useState<number>(0);

    const isEmpty = useMemo(
        () => !data.content.length && Object.values(filters).every((item) => item && item.length),
        [data.content.length, filters]
    );

    const isNew = useMemo(() => status === orderStatuses.hold, [status]);

    const debouncedFilters = useDebounceFilters([filters, sort, numberPage], 100);

    const { isDisplayObject } = useUserInfo();

    const isShowFiltersType = useMemo(() => isDisplayObject, [isDisplayObject]);

    const loadData = useCallback(async (filters: OrderFilters, page: number, size: number, sort: OrdersSorter) => {
        try {
            setIsLoadingContent(true);
            return await fetchOrders(
                { ...filters, type: !!filters.type ? filters.type : undefined, status, page, size },
                { field: sort?.field, order: sort?.order }
            );
        } catch (err) {
            console.error(err);
            eventDispatcher.setNotification({
                status: StatusRequestEnum.Error,
                body: GET_DATA_ERROR,
            });
            return clone(initialData);
        } finally {
            setIsLoadingContent(false);
        }
    }, []);

    const loadContent = useCallback(async () => {
        const newData: OrdersWithPaginate = await loadData(filters, numberPage, PAGINATE_SIZE, sort);
        setData(newData);
        setIsEmpty(!newData.totalElements);
        setTotal(newData.totalElements);
    }, [filters, status, numberPage, loadData, sort]);

    const changeNumberPage = useCallback((value: number) => {
        setNumberPage(value);
    }, []);

    useEffect(() => {
        changeFilter(INITIAL_FILTERS);
        setNumberPage(0);
    }, [status]);

    useEffect(() => {
        if (debouncedFilters > 0) {
            loadContent().then(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedFilters]);

    useEffect(() => {
        setNumberPage(0);
    }, [filters]);

    return {
        data,
        isLoadingContent,
        isShowFiltersType,
        loadData,
        isEmpty,
        total,
        isNew,
        numberPage,
        loadContent,
        changeNumberPage,
    };
};

export default useOrders;
